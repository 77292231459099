<template>

  <div 
    :class="'hscroll ' + (platform) + ' ' + (align ? align : '') + (scroll ? '' : ' noscroll-removed') + (arrowsShowHover ? ' arrows-show-hover' : '' + ' arr-size-' +  arrowsSize)"
    ref="scrollElement"
    >
 
    <div class="arr-left" v-show="arrows && isShowLeft" @click="scrollLeft()">
      <i class="icon-angle-left"></i>
    </div>

    <div class="hcscroll" ref="scrollable" @click="click">
      <slot></slot>
    </div>

    <div class="arr-right" v-show="arrows && isShowRight" @click="scrollRight()">
      <i class="icon-angle-right"></i>
    </div>

  </div>

</template>


<script setup>

const props = defineProps({
  data: {
    type: Array,
    required: false
  },
  align: {
    type: String,
    required: false
  },
  scroll: {
    type: Boolean,
    required: false,
    default: false
  },
  scrollCentered: {
    type: Boolean,
    required: false,
    default: false
  },
  arrows: {
    type: Boolean,
    required: false,
    default: false
  },
  arrowsShowHover: {
    type: Boolean,
    required: false,
    default: false
  },
  platform: {
    type: String,
    required: false,
    default: 'all'
  },
  arrowsSize: {
    type: String,
    required: false,
    default: 'small'
  }
})


const scrollElement = ref(null)
const scrollable = ref(null)
// const isScrollable = ref(false)
const isShowLeft = ref(false)
const isShowRight = ref(false)
const lastActiveChild = ref(null)
const observer = ref(null)

const isScrollable = () => {
  if(import.meta.server) return false;  
  if(!scrollable.value) return false;
  if(!scrollElement.value) return false;
  return scrollable.value.clientWidth < scrollElement.value.clientWidth;
}

const initSizes = () => { 
  toggleArrows();
}
 
const toggleArrows = () => {


  if(!isScrollable) return;
  
  const scrollLeft = scrollable.value.scrollLeft;
  const scrollWidth = scrollable.value.scrollWidth;
  const clientWidth = scrollable.value.clientWidth;
  const maxScrollLeft = scrollWidth - clientWidth;
  isShowLeft.value = scrollLeft > 20;
  isShowRight.value = scrollLeft < maxScrollLeft - 20;

  // console.log('scrollLeft, scrollWidth, clientWidth, maxScrollLeft');
  // console.log(scrollLeft, scrollWidth, clientWidth, maxScrollLeft);
}

const resizeHandler = (e) => {
  initSizes();
}

const initScroll = () => {
  scrollable.value.addEventListener('scroll', toggleArrows);
}

const scrollMove = (vl) => {
  var scrollLeft = scrollable.value.scrollLeft;
  var scrollWidth = scrollElement.value.scrollWidth;
  var step = scrollWidth; 

  scrollable.value.scroll({      
    left: scrollLeft + (step * vl),
    behavior: 'smooth'      
  });
}



const scrollToActiveChild = () => {

  if(!isScrollable()) return;
  if(!scrollable.value) return;

  const scrollLeft = scrollable.value.scrollLeft;
  const scrollWidth = scrollable.value.scrollWidth;
  const clientWidth = scrollable.value.clientWidth;
  const maxScrollLeft = scrollWidth - clientWidth;
  const activeChild = scrollable.value.getElementsByClassName("active")[0];
  if(!activeChild) return;
  const activeChildWidth = activeChild.clientWidth;
  const activeChildLeft = activeChild.offsetLeft;
  // const activeChildMargins = parseInt(window.getComputedStyle(activeChild).marginLeft) + parseInt(window.getComputedStyle(activeChild).marginRight);
  // const currentIndex = Array.from(scrollable.value.children).indexOf(activeChild);
  if(!activeChildWidth) return;
  // console.log('activeChild');
  // console.log(activeChild);
  // console.log('activeChildLeft, scrollLeft, clientWidth');
  // console.log(activeChildLeft, scrollLeft, clientWidth);
  const activeChildRight = activeChildLeft + activeChildWidth;
  const isChildVisible = activeChildLeft >= scrollLeft && activeChildRight <= scrollLeft + clientWidth;
  if(!isChildVisible || props.scrollCentered){
    lastActiveChild.value = activeChild;
    // console.log('calc scroll');
    // console.log( scrollLeft + activeChildLeft);
    scrollable.value.scroll({
      left: activeChildLeft - (clientWidth / 2) + (activeChildWidth / 2),
      // left: activeChildLeft,
      behavior: 'smooth'      
    });
  }
}

const scrollLeft = () => {
  scrollMove(-1);
}

const scrollRight = () => {
  scrollMove(1);
}

const click = (e) => {
  // setTimeout(() => {
  //   alert(1);
  //   scrollToActiveChild();
  // }, 300);
}

onMounted(() => {
  if(import.meta.client){
    initSizes();
    initScroll();
    setTimeout(() => {
      scrollToActiveChild();
    }, 500);
  }
  // window.addEventListener("resize", resizeHandler);
})

onUnmounted(() => {
  // window.removeEventListener("resize", resizeHandler);
});




onMounted(() => {
  observer.value = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const target = mutation.target;
        if (target.classList.contains('active')) {
          // scrollable.value.scrollIntoView({ behavior: 'smooth' });
        // alert(1);
          // console.log('mutation');
          scrollToActiveChild();
        }
      }
    });
  });

  const config = {
    attributes: true,
    childList: true,
    subtree: true,
    attributeFilter: ['class']
  };

  if (scrollable.value) {
    observer.value.observe(scrollable.value, config);
  }
});

onBeforeUnmount(() => {
  if(observer.value)
  observer.value.disconnect();
});

// watch(() => props.data, () => {
//   alert(2);
//   scrollToActiveChild();
// }, {deep: true})


</script>
   

<style scoped>
  
.hscroll{
  display: flex;    
  position: relative;
  width: 100%;
}
.hscroll.center{
  justify-content: center;
}
.hcscroll{
  display: flex;
  overflow-x: auto;
  /* overflow-y: hidden; */
  /* padding-bottom: 2px;   */
  width: 100%;
}
.hcscroll::-webkit-scrollbar {
  display: none;
}

.arr-left,
.arr-right{
  position: absolute;
  display: flex;
  align-items: center;
  width: 54px;
  height: 100%;
  top: 0;
  z-index: 10;
  cursor: pointer;
  font-size: 1.2rem;
}

.arr-left i,
.arr-right i{
  background-color: var(--gd-body-back-color);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  color: var(--gd-text-light-gray-color);
  font-size: 1.2rem;
  box-shadow: 0 3px 6px rgba(25, 25, 25, .16);
}

.arr-left:hover i,
.arr-right:hover i{
  box-shadow: 0 3px 6px rgba(25, 25, 25, .26);
}

.arr-left{
  left: -40px;
}

.arr-right{
  right: -40px;
}

.arr-left{
  justify-content: flex-start;
  /* background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); */
}
.arr-right{
  justify-content: flex-end;
  /* background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); */
}




/* arrows-show-hover */

.arrows-show-hover .arr-left i,
.arrows-show-hover .arr-right i{
  display: none!important;
}

.arrows-show-hover:hover .arr-left i,
.arrows-show-hover:hover .arr-right i{
  display: flex!important;
}

@media only screen and (max-width: 768px) {
  .arrows-show-hover .arr-left,
  .arrows-show-hover .arr-right{
    display: none!important;
  }
}



/* only mobile */
@container pb (min-width: 700px) {
  .hscroll.mobile{
    overflow-x: inherit;
    overflow-y: inherit;
    padding-bottom: 2px;  
    width: 100%;
  }
  .hscroll.mobile .hcscroll{
    overflow-x: inherit;
    overflow-y: inherit;
    padding-bottom: 2px;  
    width: 100%;
  }
}





/* arrows sizes */

.arr-size-small .arr-left i,
.arr-size-small .arr-right i{
  width: 25px;
  height: 25px;
  font-size: 1.1rem;
}

.arr-size-medium .arr-left i,
.arr-size-medium .arr-right i{
  width: 35px;
  height: 35px;
  font-size: 1.2rem;
}

.arr-size-large .arr-left i,
.arr-size-large .arr-right i{
  width: 45px;
  height: 45px;
  font-size: 1.4rem;
}

</style>
